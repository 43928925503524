import { useEffect, useState } from "react";
import Back from "../Icons/Back";

export default function BackButton({ onClick }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 150);
  }, []);

  let className = "button-back";

  if (isVisible) {
    className = "button-back button-back--visible";
  }

  return (
    <div onClick={onClick} className={className}>
      <Back /> Back
    </div>
  );
}
