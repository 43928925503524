import { useEffect, useState } from "react";
import "../../css/inputs.css";

export default function Inputs({
  items,
  screenIndex,
  onChange,
  onComplete,
  form,
}) {
  const [required, setRequired] = useState({});
  const [errors, setErrors] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let values = [];

    items.map(item => {
      values[item.id] = form?.[item.id] || "";

      return false;
    });

    setRequired(values);
    setIsVisible(true);
  }, []);

  const _onChange = (id, value) => {
    onChange(id, value);
    setRequired({
      ...required,
      [id]: value,
    });
  };

  const _onComplete = () => {
    setErrors([]);

    let isValid = true;
    let invalid = [];

    items.map(item => {
      if (!required?.[item.id]?.length) {
        isValid = false;
        invalid.push(item.id);
      }

      return false;
    });

    if (isValid) {
      onComplete();
    } else {
      setErrors(invalid);
    }
  };

  let className = "inputs";

  if (isVisible) {
    className = "inputs inputs--visible";
  }

  return (
    <div className={className}>
      {errors.length > 0 && (
        <div className="inputs--error">Please fill in all fields.</div>
      )}

      <div className="inputs--fields">
        {items.map((item, index) => {
          return (
            <Input
              item={item}
              index={index}
              id={`${screenIndex}-${index}`}
              key={`${screenIndex}-${index}`}
              onBlur={event => _onChange(item.id, event.target.value)}
              errors={errors}
              required={required}
            />
          );
        })}
      </div>

      <button className="inputs-button" onClick={_onComplete}>
        Next
      </button>
    </div>
  );
}

const Input = ({ id, item, onBlur, errors, required }) => {
  let classNames = "inputs-field";

  if (errors.includes(item.id)) {
    classNames += " error";
  }

  return (
    <div className={classNames}>
      <label htmlFor={id}>{item.label}</label>
      <input
        id={id}
        type={item.type}
        onBlur={onBlur}
        defaultValue={required?.[item.id]}
        placeholder={item?.placeholder}
      />
    </div>
  );
};
