import { useEffect, useState } from "react";
import "../../css/buttons.css";

export default function Buttons({ items, screenIndex, onChange, onComplete }) {
  const onClick = (questionIndex, buttonIndex) => {
    onChange(questionIndex, buttonIndex);
    onComplete();
  };

  const _screenIndex = screenIndex.match(/screen-(\d+)/)[1];

  return (
    <div className="buttons">
      {items.map((item, index) => {
        return (
          <Button
            index={index}
            screenIndex={screenIndex}
            title={item.title}
            key={`${screenIndex}-${index}`}
            onClick={() => onClick(_screenIndex, index)}
          />
        );
      })}
    </div>
  );
}

const Button = ({ title, onClick, index, screenIndex }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, (index + 1) * 100);
  }, []);

  let className = "buttons--button";

  if (isVisible) {
    className = "buttons--button buttons--button--visible";
  }

  return (
    <button
      className={className}
      onClick={onClick}
      key={`button-${screenIndex}-${index}`}
    >
      {title}
    </button>
  );
};
