import { Fragment } from "react";
import Screen from "./Screen";
import ScreenEnd from "./ScreenEnd";
import Welcome from "./Welcome";

export default function Main({
  config,
  current,
  setCurrent,
  form,
  setForm,
  getForm,
}) {
  const onChangeInput = (id, value) => {
    form[id] = value;

    setForm({
      ...form,
      [id]: value,
    });
  };

  const onChangeButton = (id, value) => {
    form[id] = value;

    setForm({
      ...form,
      [id]: value,
    });
  };

  const showNext = index => () => setCurrent(index + 1);
  const showPrev = index => () => setCurrent(index - 1);

  return (
    <main>
      <div className="container">
        {config.questions.map((question, index) => {
          if (index === current) {
            return (
              <Screen
                id={`screen-${index}`}
                key={`screen-${index}`}
                question={question}
                visible={index === current}
                current={current}
                showNext={showNext(index)}
                showPrev={showPrev(index)}
                onChangeInput={onChangeInput}
                onChangeButton={onChangeButton}
                form={form}
              />
            );
          }

          return <Fragment key={`screen-blank-${index}`}></Fragment>;
        })}

        {current < 0 && <Welcome showNext={showNext(-1)} config={config} />}

        {current >= config.questions.length && (
          <ScreenEnd getForm={getForm} config={config} />
        )}
      </div>
    </main>
  );
}
