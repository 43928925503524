import { useState } from "react";
import { Title } from "react-head";

import "./css/password.css";

export default function Password({ setPassword }) {
  const [_password, _setPassword] = useState("");

  const onBlur = e => {
    _setPassword(e.target.value);
  };

  const onEnter = () => {
    const __password = _password;
    _setPassword("");
    setPassword(__password);
  };

  return (
    <>
      <Title>Andreas Gossen</Title>

      <section id="section-password">
        <div className="container">
          <h2>Please enter Password</h2>
          <input type="text" onBlur={onBlur} placeholder="Password" />
          <button onClick={onEnter}>Enter</button>
        </div>
      </section>
    </>
  );
}
